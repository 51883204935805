import React, { useMemo } from "react";
import ServiceCenterSliderCard from "./ServiceCenterSliderCard";
import Marquee from "react-fast-marquee";
import EmployeeWithBadges from "./EmployeeWithBadges";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useCommonState } from "../CommonState";

const ScSlider = () => {

  const { state: { response }, updateState } = useCommonState();
  // console.log({ response });

  // const respData = useMemo(() => response && response && response?.all && Object.values(response?.all), [response])
  // console.log({ respData });
  const respData = useMemo(() => {
    if (response && response.all) {
      return Object.values(response.all);
    }
    return [];
  }, [response]);
  // console.log({ respData });

  const distNames = useMemo(() => {
    if (respData && respData.length > 0) {
      // Extract unique dist_name values
      const uniqueDistNames = [...new Set(respData.map(item => item.dist_name))];
      return uniqueDistNames;
    }
    return [];
  }, [respData]);

  // const distName = useMemo(() => respData && respData[0].dist_name, [respData]);
  // const employees = useMemo(() => respData && respData.sort((a, b) => a.group_rank - b.group_rank), [respData]);
  // console.log({ distNames });

  const uniqueDistNameArray = respData.reduce((result, currentObject) => {
    const existingObject = result.find((item) => item.dist_name === currentObject.dist_name);
    
    if (!existingObject) {
      // If the dist_name doesn't exist in the result array, add a new object
      result.push({
        dist_name: currentObject.dist_name,
        data: [currentObject] // Create an array containing the current object
      });
    } else {
      // If the dist_name already exists, push the current object into the existing object's data array
      existingObject.data.push(currentObject);
    }
  
    return result;
  }, []);

  // console.log({uniqueDistNameArray});

  return (
    <>
      <div className="marquee_div" style={{ background: "transparent" }}>
        <Marquee loop={0} gradient={false} speed={100} className="p-2">
          <div className="d-flex">
            {
              uniqueDistNameArray && uniqueDistNameArray?.map((data, i) =>
                //  console.log({data})
                <ServiceCenterSliderCard key={i} 
                data={data} 
                // distNames={distNames} 
                // sortedEmployees={employees} 
                />
              )
              // response && response && response?.all && response?.all?.map((data, i) => 
              // console.log({...data})
              // <ServiceCenterSliderCard {...data}/>
              // )
            }

            {/* {respData && respData?.map((data, i) => (
              <ServiceCenterSliderCard key={i} data={data} />
            ))} */}
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default ScSlider;
