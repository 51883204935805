import React from 'react'
import { RiCopperCoinFill } from 'react-icons/ri'


const IndividualEmp = ({ rank, name, total, dist_name, sc_name, profile_image, player_id, target_points }) => {
    return <>

        <div className="card p-2 emp_card me-2" style={{ width: "28rem" }}>
            {/* <div className="card-body"> */}
            <div className="row">
                <div className="col-5">
                    <img src={profile_image} alt="Avatar" className="emp_avatar" onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = 'https://www.w3schools.com/howto/img_avatar.png';
                    }} />
                </div>
                <div className="col-7 d-flex align-items-center">
                    <div className="row">
                        <div className=" bold fs-6">{name}</div>
                        <div className="fs-6">Rank - <span className='bold'>{rank}</span> </div>
                        <div className="fw-bolder fs-5 points_text">
                            <RiCopperCoinFill size={20} color="darkorange" /> {Math.floor(Number(total))} PTS
                        </div>
                        <div className="fw-bolder fs-5 points_text">
                            <RiCopperCoinFill size={20} color="green" /> {Math.floor(Number(target_points))} PTS
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </div>

    </>
}

export default IndividualEmp