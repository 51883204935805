import React from "react";
import { useCommonState } from "../CommonState";
// import ReactConfetti from "react-confetti";
import MainSliderComponent from "./MainSliderComponent";
// import Marquee from "react-fast-marquee";



const Slider = () => {

  const { state: { response }, updateState } = useCommonState();
  // console.log({ response   });

  // const ref = useRef(null);

  // const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);

  // useLayoutEffect(() => {
  //   setWidth(ref.current.offsetWidth);
  //   setHeight(ref.current.offsetHeight);
  // }, []);

  return (
    <>
      {/* <div className="card border-0" style={{background:"transparent"}}>
        <div ref={ref} className="w-100">
          <ReactConfetti width={width} height={height} /> 
          <Marquee loop={0} gradient={false} speed={100} className="p-3"> */}
      <div className="d-flex justify-content-center">
        {
          response && response && response?.top_three && response?.top_three?.map((data, i) => <MainSliderComponent {...data} />)
        }
      </div>
      {/* </Marquee>
        </div>
      </div> */}



    </>
  );
};

export default Slider;
