import React, { useMemo } from "react";
import Marquee from "react-fast-marquee";
import { useCommonState } from "../CommonState";
import IndividualEmp from "./IndividualEmp";

const IndividualEmpSlider = () => {
  const {
    state: { response },
    updateState,
  } = useCommonState();

  let marqueeSpeed = useMemo(() => {
    let apiSize = response?.all?.length;
    console.log({ apiSize }, "response");
    if (apiSize < 20) {
      return 50;
    } else if (apiSize < 100) {
      return 100;
    }
  }, [response]);
  // if(response.dashboardApi.all.length() <= 20) return speed = 30

  return (
    <>
      <div className="marquee_div" style={{ background: "transparent" }}>
        <Marquee loop={0} gradient={false} speed={marqueeSpeed} className="p-2">
          <div className="d-flex">
            {
              //   [...Array(6)].map((_, i) => (<IndividualEmp />))
              response &&
                response?.all &&
                response?.all?.map((data, i) => <IndividualEmp {...data} />)
            }
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default IndividualEmpSlider;
