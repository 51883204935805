import qs from 'qs'

function isNotEmpty(obj) {
    return obj !== undefined && obj !== null && obj !== ''
}

function stringifyRequestQuery(state) {

    const pagination = qs.stringify(state, { filter: ['page', 'items_per_page'], skipNulls: true })
    const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
    const search = isNotEmpty(state.search)
        ? qs.stringify(state, { filter: ['search'], skipNulls: true })
        : ''

    const filter = state.filter
        ? Object.entries(state.filter)
            .filter((obj) => isNotEmpty(obj[1]))
            .map((obj) => {
                return `${obj[0]}=${obj[1]}`
            })
            .join('&')
        : ''


    const _f = state.f
        ? Object.entries(state.f)
            .filter((obj) => isNotEmpty(obj[1]))
            .map((obj) => {
                return `${obj[0]}:${obj[1]}`
            })
            .join(';')
        : '';


    const final_f = state.f && Object.keys(state.f).length ? `f=${_f}` : ''

    const _q = state.q
        ? Object.entries(state.q)
            .filter((obj) => isNotEmpty(obj[1]))
            .map((obj) => {
                return `${obj[0]}:${obj[1]}`
            })
            .join(';')
        : '';

    const final_q = state.q && Object.keys(state.q).length ? `q=${_q}` : ''

    return [pagination, sort, search, filter, final_f, final_q]
        .filter((f) => f)
        .join('&')
    //.toLowerCase()
}


export {
    stringifyRequestQuery
}