import React, { useEffect, useState } from 'react'
import { fetchDashboardApi } from '../apis/apiCall'
import { CommonStateProvider, useCommonState } from './CommonState'
import WallOfFame from './WallOfFame';
import { stringifyRequestQuery } from '../helpers/helpers';
import { useParams, useSearchParams } from 'react-router-dom';


const Main = () => {

    const { state, updateState } = useCommonState()
    const { dist, ym } = useParams()

    useEffect(() => {
        fetchApi();
        return () => { }
    }, [])

    const fetchApi = async () => {

        let filter = {}

        if (dist) filter = { dist, ym: ym }
        else filter = { type: 'global', ym: ym }

        const state = {
            filter
        }

        const queryString = stringifyRequestQuery(state)

        const apiResp = await fetchDashboardApi(queryString);
        console.log({ apiResp });
        if (apiResp && apiResp) {
            updateState({
                type: !dist ? 'global' : '',
                response: apiResp
            })
        }
    }

    return <>
        <WallOfFame />
    </>
}


const WallOfFameMain = () => {

    return (
        <CommonStateProvider>
            <Main />
        </CommonStateProvider>
    )
}




export default WallOfFameMain