import React, { FC, createContext, useState, useEffect, useContext } from 'react'

const CommonStateContext = createContext({})

const CommonStateProvider = ({ children }) => {
    const [state, setState] = useState({})

    const updateState = (updates) => {
        const updatedState = { ...state, ...updates }
        setState(updatedState)
    }
    console.log(state);

    return (
        <CommonStateContext.Provider value={{ state, updateState }}>
            {children}
        </CommonStateContext.Provider>
    )
}

const useCommonState = () => useContext(CommonStateContext)
export { CommonStateProvider, useCommonState }