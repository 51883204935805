import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { useCommonState } from "./CommonState";
import IndividualEmpSlider from "./Components/IndividualEmpSlider";
import ScSlider from "./Components/ScSlider";
import Slider from "./Components/Slider";


const WallOfFame = () => {

  const { state: { response, type } } = useCommonState()
  const { dist, ym } = useParams()
  const date = moment(ym, "MM-YYYY");
  const selected_date = date.format("MMMM-YYYY")
  console.log(date.format("MMMM-YYYY"));
  // console.log(ym);

  // console.log(moment(ym).format("MMMM-YYYY"));
  // console.log({ response });

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row points">
          {/* <div className="col-md-2">
            <Chairman />
          </div> */}
          <div className="col-md-10">
            {response && response?.all && <>
              <h1 className="text-center display-6 fw-bolder card_message">Top 3 Performers of {selected_date}</h1>
              <Slider />
            </>}
          </div>
          {/* <div className="col-md-2">
            <Points />
          </div> */}
        </div>
        <div className="row mb-4">
          <div className="col-md-12">

            {type && type == 'global' && response && response?.all && <><h1 className="text-center display-6 fw-bolder card_message">TOP 3 Performers Districtwise of {selected_date}</h1>
              <ScSlider /></>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {!type && response && response?.all && <IndividualEmpSlider />}
            {/* <div className="d-flex">
              {
                [...Array(6)].map((_, i) => (<IndividualEmp />))
              }
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
// style={{height:"400px"}}
export default WallOfFame;
