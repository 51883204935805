import React, { useMemo } from 'react'
import { RiCopperCoinFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom';
import { useCommonState } from '../CommonState';

// const ServiceCenterSliderCard = ({ rank, name, total, dist_name, sc_name, profile_image, player_id, target_points }) => {
const ServiceCenterSliderCard = ({ data, distNames, sortedEmployees }) => {

  const navigate = useNavigate();
  const { state, updateState } = useCommonState()
  console.log({ state });

  // console.log({ group_rank, name, total, dist_name, sc_name, profile_image, player_id });

  // console.log({ data, distName, sortedEmployees });
  // const distName = useMemo(() => data && data[0].dist_name, [data]);
  // const employees = useMemo(() => data && data.sort((a, b) => a.group_rank - b.group_rank), [data]);


  const defaultAvatar = 'https://uat-topupp.orangecurrent.com/uploads/default.png'

  const onCLickHandler = () => {
    navigate("/district/" + data.dist_name + "/" + state.ym);
  }

  // console.log({distNames});
  // console.log(data);
  const employees = useMemo(() => data && data, [data])

  // console.log({employees});


  return (
    // <>
    //   Hello</>
    <div className="d-flex align-items-center card p-2 mt-3 me-4 district_name" style={{ width: "600px" }} onClick={onCLickHandler}>
      <span className="badge rounded-pill bg-success sc_name_badge fs-5">{data.dist_name}</span>
      <div className="row align-items-center mt-3">
        <div className="col-md-4">
          <img src={employees && employees?.data[1]?.profile_image || defaultAvatar} alt="Avatar" className="avatar border border-light p-1 border-5" onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultAvatar;
          }} />
          <div className="row text-center">
            <span className="lh-2 fw-bold text-wrap" style={{ fontSize: "medium" }}>{employees && employees?.data[1]?.name}</span>
            <span className="text-center fw-bolder fs-4" style={{ color: "darkblue" }}><RiCopperCoinFill size={20} color="darkorange" /> {employees && employees?.data[1] && employees && employees?.data[1]?.total >= 100000 ? "NAN" : `${Math.floor(Number(employees && employees?.data[1]?.total))}`} PTS</span>
            <span className="text-center fw-bolder fs-4" style={{ color: "darkblue" }}><RiCopperCoinFill size={20} color="green" /> {employees && employees?.data[1] && employees && employees?.data[1]?.target_points >= 100000 ? "NAN" : `${Math.floor(Number(employees && employees?.data[1]?.target_points))}`} PTS</span>
            {/* <span className="text-center fw-bolder fs-4" style={{color:"darkblue"}}><RiCopperCoinFill size={20} color="darkorange" /> {employees && employees[1] && Math.floor(Number(employees && employees[1]?.total))} PTS</span> */}
          </div>
        </div>
        <div className="col-md-4">
          <img src={employees && employees?.data[0]?.profile_image || defaultAvatar} alt="Avatar" className="avatar_middle border border-warning p-1 border-5" onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultAvatar;
          }} />
          <div className="row text-center">
            <span className="lh-2 fw-bold text-wrap" style={{ fontSize: "medium" }}>{employees && employees?.data[0]?.name}</span>
            {/* <span className="text-center fw-bolder fs-4" style={{color:"darkblue"}}><RiCopperCoinFill size={20} color="darkorange" /> {employees && employees?.data[0] && Math.floor(Number(employees && employees?.data[0]?.total))} PTS</span> */}
            <span className="text-center fw-bolder fs-4" style={{ color: "darkblue" }}><RiCopperCoinFill size={20} color="darkorange" /> {employees && employees?.data[0] && employees && employees?.data[0]?.total >= 100000 ? "NAN" : `${Math.floor(Number(employees && employees?.data[0]?.total))}`} PTS</span>
            <span className="text-center fw-bolder fs-4" style={{ color: "darkblue" }}><RiCopperCoinFill size={20} color="green" /> {employees && employees?.data[0] && employees && employees?.data[0]?.target_points >= 100000 ? "NAN" : `${Math.floor(Number(employees && employees?.data[0]?.target_points))}`} PTS</span>
          </div>
        </div>
        <div className="col-md-4">
          <img src={employees && employees?.data[2]?.profile_image || defaultAvatar} alt="Avatar" className="avatar bronze" onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultAvatar;
          }} />
          <div className="row text-center">
            <span className="lh-2 fw-bold text-wrap" style={{ fontSize: "medium" }}>{employees && employees?.data[2]?.name}</span>
            {/* <span className="text-center fw-bolder fs-4" style={{color:"darkblue"}}><RiCopperCoinFill size={20} color="darkorange" /> {employees && employees?.data[2] && Math.floor(Number(employees && employees?.data[2]?.total))} PTS</span> */}
            <span className="text-center fw-bolder fs-4" style={{ color: "darkblue" }}><RiCopperCoinFill size={20} color="darkorange" /> {employees && employees?.data[2] && employees && employees?.data[2]?.total >= 100000 ? "NAN" : `${Math.floor(Number(employees && employees?.data[2]?.total))}`} PTS</span>
            <span className="text-center fw-bolder fs-4" style={{ color: "darkblue" }}><RiCopperCoinFill size={20} color="green" /> {employees && employees?.data[2] && employees && employees?.data[2]?.target_points >= 100000 ? "NAN" : `${Math.floor(Number(employees && employees?.data[2]?.target_points))}`} PTS</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceCenterSliderCard