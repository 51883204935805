import React, { useEffect, useState } from 'react'
import { fetchDashboardApi } from '../apis/apiCall'
import { CommonStateProvider, useCommonState } from './CommonState'
import WallOfFame from './WallOfFame';
import { stringifyRequestQuery } from '../helpers/helpers';
import { useParams, useSearchParams } from 'react-router-dom';


const Main = () => {

    const { state, updateState } = useCommonState()
    const { dist, ym } = useParams()
    // console.log({dist, ym });

    useEffect(() => {
        fetchApi();
        return () => { }
    }, [])

    const fetchApi = async () => {

        let filter = {}

        if (dist) {
            filter = {
                dist, ym: ym
            }
            console.log({ filter });
        }
        else {
            filter = { type: 'global', ym: ym }
            // console.log({ filter });
        }

        const state = {
            filter
        }


        console.log({ state });
        const queryString = stringifyRequestQuery(state)
        console.log({ queryString });
        const apiResp = await fetchDashboardApi(queryString);
        // console.log({ apiResp });
        if (apiResp && apiResp) {
            updateState({
                type: !dist ? 'global' : '',
                ym: ym,
                response: apiResp
            })
        }
    }

    return <>
        <WallOfFame />
    </>
}


const WallOfFameMain = () => {

    return (
        <CommonStateProvider>
            <Main />
        </CommonStateProvider>
    )
}




export default WallOfFameMain