import React, { useMemo, lazy } from 'react'
// import medalIcon from "../../MedalIcon.png";
import { RiCopperCoinFill } from 'react-icons/ri';
import bgImage1 from '../../frameNo1.png'
import bgImage2 from '../../frameNo2.png'
import bgImage3 from '../../frameNo3.png'
// import frame from "../../frameNo1.png";


const MainSliderComponent = ({ rank, name, total, dist_name, sc_name, profile_image, player_id, target_points }) => {

    const renderImage = useMemo(() => {
        if (rank == 1) return bgImage1
        if (rank == 2) return bgImage2
        if (rank == 3) return bgImage3
        // return lazy(() => import(`../../frameNo${group_rank}.png`))
    }
        // , [group_rank]
    )

    // console.log({ rank, name, total, dist_name, sc_name, profile_image, player_id, target_points });
    return (
        <>
            <div className="frame" style={{ backgroundImage: `url(${renderImage})` }}>
                <div className='w-100 h-100 ' style={{ paddingRight: '3.5rem' }}>
                    <div className="d-flex w-100 h-100  align-items-top justify-content-center fw-bold fs-4 mb-1 text text-dark pt-3">
                        <div className='d-flex flex-column align-items-center mt-2 mx-5 px-5 mb-3'>
                            <b className='award_title'>Best Award</b>
                            {/* <div className="banner">Best Award</div> */}
                            <img
                                src={profile_image}
                                alt="Avatar"
                                className="avatar mb-2"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = 'https://www.w3schools.com/howto/img_avatar.png';
                                }}
                            />
                            <div className="row text-center">
                                <span
                                    className="fw-bold text-wrap text-dark mb-1 lh-sm"
                                    style={{ fontSize: "14px" }}
                                >
                                    {name}
                                </span>
                                <span className=" fw-bolder points_text">
                                    {/* <RiCopperCoinFill size={20} color="darkorange" /> {Math.floor(Number(total))} PTS */}
                                    <RiCopperCoinFill size={20} color="darkorange" /> {total >= 100000 ? "NAN" : `${Math.floor(Number(total))}`} PTS
                                </span>
                                <span className=" fw-bolder points_text" >
                                    <RiCopperCoinFill size={20} color="green" /> {target_points >= 100000 ? "NAN" : `${Math.floor(Number(target_points))}`} PTS
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainSliderComponent